import React from 'react';
import { graphql, PageProps } from 'gatsby';
import Layout from '@/components/Layout';
import Seo from '@/components/Seo';
import { MOODS } from '../../../constants';
import classNames from 'classnames';
import { useIntl } from 'react-intl';
import ProductCard from '@/components/ProductCard';

type PageContextProps = {
  id: string;
};

type DataProps = {
  allShopifyProduct: any;
  allDatoCmsProduct: any;
  datoCmsOverview: any;
};

const Parterre = ({ data }: PageProps<DataProps, PageContextProps>) => {
  const intl = useIntl();
  const coffees = data.allShopifyProduct.nodes;

  return (
    <Layout
      variant="dark"
      navChangeColor={false}
      alternatePages={[
        { locale: 'de', value: 'parterre/coffees' },
        { locale: 'en', value: 'parterre/coffees' },
      ]}
    >
      <Seo title="Parterre Coffees" />
      <section className="bg-white">
        <div className="relative pt-32 flex flex-col rounded-b-4xl">
          <div className="text-center mx-auto max-w-screen-md px-4 lg:px-0">
            <div className="pt-12 text-6xl md:text-6xl font-medium">
              {intl.formatMessage({ id: 'parterreCoffeeTitle' })}
            </div>
            <div className="mt-6 font-normal text-xl ">
              {intl.formatMessage({ id: 'parterreCoffeeSubtitle' })}
            </div>
          </div>
        </div>
      </section>
      <section className="bg-white pt-8 lg:pt-4 pb-28">
        <div className="pt-12 max-w-screen-2xl 2xl:mx-auto 2xl:px-16 pb-10 sm:pb-0">
          <div className="lg:flex gap-5">
            <div className="flex lg:flex-row lg:flex-wrap gap-5 overflow-x-auto px-4 w-full">
              {coffees.map((product: any) => {
                const productContent = data.allDatoCmsProduct.nodes.find(
                  (item: any) => item.productId === product.shopifyId,
                );
                return (
                  <div
                    key={product.id}
                    className={classNames(
                      'flex-none rounded-4xl bg-opacity-20 border w-11/12 lg:w-[31%] border-opacity-30 hover:bg-opacity-30 hover:border-opacity-40',
                      {
                        [MOODS[product.mood.value]?.bg]: product.mood.value,
                        [MOODS[product.mood.value]?.border]: product.mood.value,
                      },
                    )}
                  >
                    <ProductCard
                      product={{
                        ...product,
                        ...productContent,
                      }}
                    />
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export const query = graphql`
  query ($locale: String) {
    datoCmsOverview(locale: { eq: $locale }) {
      title
      description
    }
    allDatoCmsProduct(filter: { locale: { eq: $locale } }) {
      nodes {
        productId
        description
        tags {
          title
          text
        }
        beans {
          country
          location
          name
          estate
          image {
            gatsbyImageData
          }
          altitude
          description
          species
          variety
          preparation
          harvestTime
          certificates
          traceability {
            title
            price
            summary
          }
        }
      }
    }
    allShopifyProduct(
      filter: {
        collections: {
          elemMatch: { id: { eq: "535517d5-475f-512a-bd50-05b548e1c68e" } }
        }
      }
    ) {
      nodes {
        id
        shopifyId
        title
        description
        handle
        featuredImage {
          gatsbyImageData(height: 350)
        }
        collections {
          id
        }
        mood: metafield(key: "mood", namespace: "specs") {
          key
          value
        }
        suitedFor: metafield(key: "suitedFor", namespace: "specs") {
          key
          value
        }
        priceRangeV2 {
          maxVariantPrice {
            amount
            currencyCode
          }
          minVariantPrice {
            amount
            currencyCode
          }
        }
        options {
          name
          values
          id: shopifyId
        }
        variants {
          availableForSale
          storefrontId
          title
          price
          selectedOptions {
            name
            value
          }
          acidity: metafield(key: "acidity", namespace: "specs") {
            namespace
            value
          }
          bitterness: metafield(key: "bitterness", namespace: "specs") {
            namespace
            value
          }
          body: metafield(key: "body", namespace: "specs") {
            namespace
            value
          }
          tasteNotes: metafield(key: "taste_notes", namespace: "specs") {
            namespace
            value
          }
          roastLevelDescription: metafield(
            key: "roastLevelDescription"
            namespace: "specs"
          ) {
            namespace
            value
          }
        }
      }
    }
  }
`;

export default Parterre;
